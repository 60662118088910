//TEXT SIZER
function addTextSizer() {
	$('#Breadcrumbs').after('<div id="TextSizer" class="ClearFix"><ul>' +
				'<li><a class="NormalFont" href="javascript:;" title="Small Font Size"></a></li>' +
				'<li><a class="LargeFont" href="javascript:;" title="Medium Font Size"></a></li>' +
				'<li><a class="X-LargeFont" href="javascript:;" title="Large Font Size"></a></li>' +
			'</ul></div>');
	$('a.NormalFont').click(function() {$('#Content').removeClass('textLarge').removeClass('textXLarge');$.cookie('text_sizer', null, { path: '/' });});
	$('a.LargeFont').click(function() {$('#Content').addClass('textLarge').removeClass('textXLarge');$.cookie('text_sizer', 'large', { path: '/' });});
	$('a.X-LargeFont').click(function() {$('#Content').removeClass('textLarge').addClass('textXLarge');$.cookie('text_sizer', 'xlarge', { path: '/' });});
	$('#TextSizer a').click(function() {$('#TextSizer a').removeClass('active');$(this).addClass('active');});
}

function text_size_checker() {
	var text_sizer_cookie = $.cookie('text_sizer');
	if (text_sizer_cookie==null){$('a.NormalFont').addClass('active')};
	if (text_sizer_cookie=='large'){ $('#Content').addClass('textLarge');$("a.LargeFont").addClass('active') };
	if (text_sizer_cookie=='xlarge'){ $('#Content').addClass('textXLarge');$("a.X-LargeFont").addClass('active') };
}

function addPageTools(){
	var clientDomainName = '[clientDomainName]';
	var clientSiteName = '[clientSiteName]';
	var titleEl = document.getElementsByTagName('title')[0];
	var currentPageTitle = '';
	if (titleEl.text){ currentPageTitle = escape(titleEl.text); } else { currentPageTitle = escape(title.data); }
	var currentPageUrl = document.location;
	var emailSubject = currentPageTitle;
	var emailHref = 'mailto:?subject=' + emailSubject + '&body=I thought you would be interested in the following page: ' + currentPageTitle + ' - ' + currentPageUrl;
	$('#PageToolsWrapper').append('<div id="PageTools"><h2 class="Hide">Page Tools</h2>' +
		'<ul>' +
			'<li class="PrintLink"><a href="#">Printer Friendly</a></li>' +
			'<li class="EmailLink"><a href="#">Email Page</a></li>' +
		'</ul>' +
	'</div>');
	$('li.EmailLink a').attr({ href: emailHref});
	$('li.PrintLink a').click(function() { print(); return false; });
}

function BasicSearch() {
    // This markup is added to the panel    
    // Markup to look like this:
    //    <div class="basicSearch">
    //        <input class="theKeyword" name="keyword" type="text" />
    //        <input class="theSearchPage" type="hidden" value="/site-search" />
    //        <input class="thisInputText" type="hidden" value="i am looking for" />
    //        <input type="hidden" class="querystr" value="term" >
    //        <input type="hidden" class="sorting" value="0" >
    //        <input type="hidden" class="errorText" value="Please enter in your search criteria." >
    //        <input class="Button basicSearchButton" type="button" value="Search" />
    //    </div>
    // NOTE: querystr and sorting inputs are OPTIONAL - if they don't exist, they default to "keyword" and "7" respectively

    $('.basicSearch').each(function () {
        var $BS = $(this),
                defaultText = $BS.find('input.thisInputText').val(),
                $input = $BS.find('input.theKeyword'),
                searchPage = $.url.attr('protocol') + '://' + $.url.attr('host') + $BS.find('input.theSearchPage').val() + '?',
                querystr = $BS.find('input.querystr').val() || '',
                querystr = (querystr.length) ? querystr + '=' : 'keyword=',
                sorting = $BS.find('input.sorting').val() || '',
                sorting = sorting !== '0' ? (sorting.length ? 'sort=' + sorting + '&' : 'sort=7&') : '',
                errorText = $BS.find('input.errorText').val() || '',
                errorText = (errorText.length) ? errorText : 'Please enter in your search criteria.';
        if (defaultText.length) { $input.val(defaultText) };
        // keyboard input bindings
        $input
                .bind('focus', function () { if ($(this).val() == defaultText) { $(this).val('') } })
                .bind('blur', function () { if ($(this).val() == '') { $(this).val(defaultText) } })
                .bind('keypress', function (e) {
                    $BS.find('.error').slideUp().remove();
                    if (e.keyCode == 13) {
                        e.preventDefault(); // dont submit the ASP.Net form
                        if (defaultText.length == 0) { defaultText = '' };
                        var kword = $(this).val();
                        if (kword.length && kword !== defaultText) { window.location = (searchPage + sorting + querystr + kword) } else { showBSError($input) }
                    }
                });
        // clicking on the search button
        $BS.find('.basicSearchButton').click(function () {
            if (defaultText.length == 0) { defaultText = '' };
            var kword = $input.val();
            if (kword.length && kword !== defaultText) { window.location = (searchPage + sorting + querystr + kword) } else { showBSError($input) }
        });
        // show error message
        function showBSError(input) {
            if ($BS.find('.error').size() == 0) { input.before('<div style="color:red" class="error">' + errorText + '</div>') }

        }
    });
}

// moving elements for mobile
$(function () {

    WindowSize();  // on page load, check window size and update the css
    MoveElements();

    $(window).resize(function () { // on window resize update the css
        WindowSize();
        MoveElements();
    });

});
function WindowSize() {

    var w = $('#BodyWrapper').width(),
        type = 'Default';

    if (w <= 729) {
        type = 'Mobile';
    }
    else if (w >= 730) {
        type = 'Desktop';
    }

    // change the css file of the tag with id="stl" and rel="stylesheet"
    $('body').removeClass('Default').removeClass('Desktop').removeClass('Mobile').addClass(type);

}
function MoveElements() {
    // Move Elements for Mobile versions
    $('body.Desktop').each(function () {
        $('#Logo').insertBefore('#HeaderRight');
        $('#TopNav').insertAfter('#HeaderRightTop');
        $('#MainNavWrapper').removeAttr('style');
        $('#Phone').appendTo('#HeaderRightTop');
        $('#Search').insertAfter('#Phone');
        $('#IWantTo').insertBefore('#SocialMedia');
        $('.CalendarDetail #VsMasterPage_MainContent_RelatedEventsTab').appendTo('.AboveTabs');
    });
    $('body.Mobile').each(function () {
        $('#Logo').insertAfter('.Payloads');
        $('#Search').appendTo('#MovedSearch');
        $('#TopNav').insertAfter('#MainNav');
        $('#Phone').insertBefore('ul.Triggers');
        $('#IWantTo').insertAfter('#TopNav');
        $('.CalendarDetail #VsMasterPage_MainContent_RelatedEventsTab').appendTo('.CalendarDetail');
    });
}



$(document).ready(function () {
    addTextSizer();
    text_size_checker();
    addPageTools();
    BasicSearch();

    // ----------- TEMPLATE EXTRAS ------------------//


    // Moving prox nav within main nav
    $('body #ProximityNav').appendTo('#MainNav li.Active');
    // Removing Proximity Nav h2 if on page not in subsection.
    if ($('#Breadcrumbs p a[href]').length < 2) {
        $('#ProximityNav').addClass('RemoveH2');
    } else {
        $('#ProximityNav').addClass('KeepH2');
    }

    // removing h2 from prox nav
    $('.RemoveH2 span:contains("Home")').each(function () {
        if ($('#ProximityNav ul li').length == 0) {
            $('#ProximityNav h2').remove();
        }
    });



    $('.JSOff').addClass('JSOn');
    $('.JSOff').removeClass('JSOff');
    $('.BodyJSOff').addClass('BodyJSOn');
    $('.BodyJSOff').removeClass('BodyJSOff');

    // Removing I Want To button and IWantToSlide if no .cmspanel exists.
    $('#IWantToButton').addClass('Hidden');
    if ($('#IWantToSlide .cmspanel').length > 0) {
        $('#IWantToButton').removeClass('Hidden');
    }
    $('#IWantTo div.Hidden').parent().remove();

    // I want to button
    $('.SlideButton').click(function () {
        if ($('#IWantToInside').hasClass('IsVisible')) {
            $('#IWantToInside').animate({ right: '-282px' }).removeClass('IsVisible');
        }
        else {
            $('#IWantToInside').animate({ right: '0' }).addClass('IsVisible');
        }
    });
	
	if ($('.randomCarousel').length ) {
	    $('.randomCarousel').css('display','block'); //hidden in css to avoid jumping
	    $('.randomCarousel').flexslider({
		animation: "slide",
		animationLoop: false,
		itemWidth: 270,
		itemMargin: 0,
		minItems: 1,
		maxItems: 3,
		randomize: true
	    });
    }
	
	//make panels even heights
	   function equalHeight(group) {
		var tallest = 0;
		group.each(function () {
			var thisHeight = $(this).height();
			if (thisHeight > tallest) {
				tallest = thisHeight;
			}
		});
		group.css('min-height',tallest);
	   }
	   equalHeight($(".Desktop #ThreeColPanels .Dec1"));
	   
	$('#ThreeColPanels .Events-Upcoming-Calendar .basicSearch').insertAfter('.FormContainer');


    // styles for Calendar Details 
    $('.CalendarDetail td.DateBox').remove();
    $('.CalendarDetail td.DateBoxHyphen').remove();
    $('.CalendarDetail .CalendarEventDetailHeader td:empty').remove();
    $('.CalendarDetail .CalendarDetailContent fieldset h1').prependTo('.CalendarDetail');
    $('.CalendarDetail .CalendarDetailContent fieldset').remove();
    $('.CalendarDetail .CalendarDetailContent .CalendarDetailContent').prepend('<div class="DetailHeader"><h3>Where:</h3></div><div class="DetailLocation"></div>');
    $('.CalendarDetail .CalendarDetailContent .CalendarDetailContent').prepend('<div class="DetailHeader"><h3>When:</h3></div><div class="DetailTime"></div>');
    $('.CalendarDetail .CalendarDetailContent td.EventDetailTime').children().appendTo('.DetailTime');
    $('.CalendarDetail .CalendarDetailContent td.EventDetailLocation').children().appendTo('.DetailLocation');
    $('.CalendarDetail .CalendarDetailContent table.CalendarEventDetailHeader').remove();

    $('.CalendarDetail p:empty').addClass('NoMargin');
    $('.CalendarDetail .AboveTabs .TopicText').prependTo('#VsMasterPage_MainContent_TopicText');
    $('.CalendarDetail .move-to-top').prependTo('#VsMasterPage_MainContent_TopicText .TopicText');
    $('#VsMasterPage_MainContent_FacebookLikeContainer').appendTo('.CalendarDetail .CalendarDetailTop');

    $('.CalendarDetail .Tab table.list').addClass('Responsive');

    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list td:nth-child(1)').addClass('First');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list td:nth-child(2)').addClass('Second');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list td:nth-child(3)').addClass('Third');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list td:nth-child(4)').addClass('Fourth');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list td:nth-child(5)').addClass('Fifth');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list th:nth-child(1)').addClass('First');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list th:nth-child(2)').addClass('Second');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list th:nth-child(3)').addClass('Third');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list th:nth-child(4)').addClass('Fourth');
    $('.CalendarDetail #VsMasterPage_MainContent_MeetingSummaryDetails_MeetingsHolder table.list th:nth-child(5)').addClass('Fifth');
	
	// Events Smart Panel
	$('.BoxDay').each(function () {
        $(this).insertAfter($(this).parent().parent().parent().find('.EventInfo .EventName')).append(' at&nbsp;');
    });
	$('.Ongoing').each(function () {
		$(this).parent().find('.EventInfo .EventName').after('<span class="BoxDay">Ongoing event</span>');
    });


    //Drop Down Styles
    // adds a width to the .Flyout .Dec div if it is shorter than Toggle anchor
    $('.DropDown').not('.Dropdown-Small').each(function () {
        var tw = $(this).find('a.Toggle').width();
        var fw = $(this).find('.Flyout').width();
        var mw = (tw - 22);
        if (fw < tw) {
            $(this).find('.Dec').css('width', mw);
        }
    });
    //Creates DropDown style function
    $('.Toggle').live('click', function () {
        var trigger = $(this);
        var triggerHeight = trigger.outerHeight();
        var triggerPosition = trigger.offset();
        var thePop = $(this).siblings('.Flyout');
        var popHeight = thePop.height();
        var docHeight = $(document).height();
        var winHeight = $(window).height();
        var scrolledHeight = $(window).scrollTop();

        var heightAboveTrigger = (triggerPosition.top - scrolledHeight);
        var heightBelowTrigger = winHeight - heightAboveTrigger - triggerHeight;

        var offset = 0;

        // if there's not enough room below, then set postion to be above trigger
        if (heightBelowTrigger < popHeight) {
            offset = -popHeight;
            // unless there's not enough room above it, then show below
            if (heightAboveTrigger < popHeight) {
                offset = $(this).outerHeight();
            }
            // except when we're at the end of the document, and there's not enough room below but there is above then show it above
            if ((docHeight - (winHeight + scrolledHeight) < popHeight) && (heightBelowTrigger < popHeight) && ((heightAboveTrigger + scrolledHeight) > popHeight)) {
                offset = -popHeight;
            }
        }
        // if there is enough room to show below, then do it
        else {
            offset = $(this).outerHeight();
        }

        // set z-index for dropdown then show the popup
        trigger.closest('.DropDown').css({ 'z-index': '3000' });
        //        thePop.css({ 'top': offset }).show();
        thePop.css({ 'top': offset, visibility: 'visible' });

    });

    // on mouseout, hide the popup
    $('.DropDown').mouseleave(function () {
        //        $(this).find('.Flyout').hide();
        $(this).find('.Flyout').css({ visibility: 'hidden' });
        $(this).css({ 'z-index': '0' });
    });


    // QuickLinks
    // toggle slide - old templates
    $('#QuickLinks .cmspanel ul').hide();
    $('#QuickLinks a.Toggle').click(function () {
        $('#QuickLinks .cmspanel ul').slideToggle();
        $('#QuickLinks a.Toggle').toggleClass('over');
        return false;
    });

    // Hide QuickLinks if panel doesn't exist - old templates
    $('#QuickLinks:empty').css('display', 'none');

    // Hide QuickLinks if panel doesn't exist - old templates
    $('.BackBtn').click(function () {
        window.history.back();
    });

    //Breadcrumbs
    if ($('.2012 #Breadcrumbs p').size() > 0) {
        if ($('.2012 #Breadcrumbs p').html().length) {
            $('#Breadcrumbs p').html(
                $('#Breadcrumbs p').html().replace(/&gt;/g, '<img src="/imgs/2012/arrow-bullet.gif" alt="" />')
            );
        }
    }

    // Main Nav adding a span
    $('.2012 #MainNav li a, #MainNav li strong').each(function (i, link) {
        var text = $(link).text();
        $(link).html(text.replace(/(.+)(\s)/, '$1 <span class="Large">'));
    });

    // adding class names to main nav
    $('#Home2012 #MainNav li:nth-child(2)').addClass('Second');
    $('#Home2012 #MainNav li:nth-child(3)').addClass('Third');
    $('#Home2012 #MainNav li:nth-child(4)').addClass('Fourth');
    $('#Home2012 #MainNav li:nth-child(5)').addClass('Fifth');
    $('#Home2012 #MainNav li:nth-child(6)').addClass('Sixth');

    $('.cmspage:empty').addClass('NoMargin');

    $('.Section .cmspanel').after('<div class="NavigationWrapper"><div class="Navigation"><a class="Top" title="Back to Top" href="#Top">Back to Top</a><br /><a class="Next" title="Next" href="">Next</a></div></div>');
    $('#Home2012 a.Next:last').remove();
    $('#Home2012 a.Top:last').addClass('TopLast');
    $('#Home2012 .Navigation a.Top:first').remove();

    $("#Home2012 #Banner .Navigation a.Next").attr("href", "#Section1");
    $("#Home2012 #Section1 .Navigation a.Next").attr("href", "#Section2");
    $("#Home2012 #Section2 .Navigation a.Next").attr("href", "#Section3");
    $("#Home2012 #Section3 .Navigation a.Next").attr("href", "#Section4");
    $("#Home2012 #Section4 .Navigation a.Next").attr("href", "#Section5");

    $('#Home2012 .NavigationWrapper a').click(function () {
        var target = $(this);
        var hash = this.hash;
        var destination = $(hash).offset().top;

        stopAnimatedScroll();

        $('#MainNav li').removeClass('Active');
        target.parent().addClass('Active');

        $('html, body').stop().animate({
            scrollTop: destination
        }, 400, function () { window.location.hash = hash; });
        return false;
    });

    function stopAnimatedScroll() {
        if ($('*:animated').length > 0) { $('*:animated').stop(); }
    }
    if (window.addEventListener) {
        document.addEventListener('DOMMouseScroll', stopAnimatedScroll, false);
    }
    document.onmousewheel = stopAnimatedScroll;

    if ($('body').hasClass('Home2012')) {
        var $filter = $('#MainNavWrapper');
        var $filterSpacer = $('<div />', {
            "class": "filter-drop-spacer",
            "height": $filter.outerHeight()
        });
    }

    /* Fixing Nav to top once scrolled there */
    var $window = $(window),
    $navigation = $(".CustomToggles"),
    offsetnav = $navigation.offset();

    /* sticky nav */
    $window.scroll(function () {
        if ($window.scrollTop() > offsetnav.top) {
            $navigation.addClass('Fixed');
        } else {
            $navigation.removeClass('Fixed');
        }
    });

    /* Mobile Toggle */
    $('.CustomToggles').each(function () {
        // create variables to use for the triggers and payloads
        var $CT = $(this),
            $triggers = $CT.find('.Triggers>li'),
            $payloads = $CT.find('.Payloads>li');

        // loop thru the triggers - using "i" as the counter, you can associate the tigger with the payload
        $triggers.each(function (i, trigger) {

            // clicking a trigger will slide up all payloads and will only slide down
            // if the associated payload wasn't the one you triggered
            $(trigger).click(function () {
                $triggers.removeClass('Active');

                if ($payloads.eq(i).is(':hidden')) {
                    $payloads.slideUp();
                    // slide down the associated payload
                    // .slideDown(1000) is 1 second
                    // .slideDown(1000, function(){ do.something })
                    // do.something happens after the slideDown command is finished.
                    // zIndexTop is a class to make sure that the one you
                    // clicked on is always sliding over the others.
                    $payloads.eq(i).addClass('zIndexTop').slideDown(300, function () {
                        $triggers.eq(i).addClass('Active');
                        $(this).removeClass('zIndexTop');
                    });
                }
                else {
                    $payloads.slideUp();
                }
            });
        });

    });

    //Remove In This Section button if no proximity nav is present.
    if ($('#ProximityNav ul').length == 0) {
        $('.SkipTo, #ProximityNavWrapper').remove();
        $('#Col1 .cmspanel:first-child').addClass('NoTopBorder');
    }
    
    
// Card Template styles
if ($('#CardTemplate').length ) {

	// adding classes for styling and counting cards
	$('#Cards .cmspanel').addClass('NormalCard');
	$('#FeaturedCards .cmspanel').removeClass('NormalCard').removeClass('default-skin').addClass('FeaturedCard');

	// calculating how far to space out the featured cards
	var allCardsCount = $('#Cards .cmspanel').length;
	var featuredNum = $('#Cards .FeaturedCard').length;
	var cardsNum = allCardsCount - featuredNum;
	//var featuredBreak = Math.floor((cardsNum / featuredNum) / 2);
	
	// if featured cards exist, wrap normal cards
	if ($('.FeaturedCard').length ) {
		var elems = $(".NormalCard");
		var wrapper = $('<div class="NormalCardWrapper" />');
		var pArrLen = elems.length;
		for (var i = 0;i < pArrLen;i+=2){
		    elems.filter(':eq('+i+'),:eq('+(i+1)+')').wrapAll(wrapper);
		};
	}
	
	$('#FeaturedCards .cmspanel').each(function (i) {
	    $(this).addClass('Featured' + i);
	});
	
	$('.NormalCard').each(function (i) {
	    //$(this).addClass('Card' + i);
	});
	
	$('#Cards .NormalCardWrapper').each(function (i) {
	    $(this).addClass('NormalCardWrapper' + i);
	});
	
	// normal card image style
	$('.NormalCard .cmsPanelContent img.Middle').each(function () {
		$(this).closest('.NormalCard').addClass('Middle');
	});
	$('.NormalCard .cmsPanelContent img.Bottom').each(function () {
		$(this).closest('.NormalCard').addClass('Bottom');
	});
	
	$('.NormalCard .cmsPanelContent img').each(function () {
		 var imgSrc = $(this)[0].src;
		 $(this).closest('.cmsPanelContent').wrapInner('<div class="Text default-skin"></div>');
		 $(this).closest('.NormalCard').css('background', 'transparent url(' + imgSrc + ') no-repeat top center');
		 $(this).remove();
	});
	
	// featured card image style
	function FeaturedCardDesktop() {
		$('.Desktop .FeaturedCard .flexslider li img').each(function () {
			 var imgSrc = $(this)[0].src;
			 $(this).css('visibility', 'hidden');
			 $(this).closest('li').css('background', 'transparent url(' + imgSrc + ') no-repeat top center');
		});
	}
	
	function FeaturedCardMobile() {
		$('.Mobile .FeaturedCard .flexslider li img').each(function () {
			 $(this).css('visibility', 'visible');
		});
	}
	
	// moving elements on load
	var w = $('#BodyWrapper').width();
	if (w <= 729) {
		FeaturedCardMobile();
	} else if (w <= 960) {
		$('.FeaturedCard').appendTo('#FeaturedCards');
		FeaturedCardDesktop();
	} else {
		
		$('.Desktop .FeaturedCard').each(function (i, el) {
			if (i > 0) {
				//console.log(i*3-1);
				//$(el).insertAfter('.NormalCardWrapper:eq(0)');
				//$(el).css('border', '5px solid #ccc');
				//$('.Desktop .Featured' + i).insertAfter('.NormalCardWrapper' + i*3-1);
			}
		});
		
		
		$('.Desktop .Featured1').insertAfter('.NormalCardWrapper2');
		$('.Desktop .Featured2').insertAfter('.NormalCardWrapper5');
		$('.Desktop .Featured3').insertAfter('.NormalCardWrapper6');
		$('.Desktop .Featured4').insertAfter('.NormalCardWrapper7');
		$('.Desktop .Featured5').insertAfter('.NormalCardWrapper10');
		$('.Desktop .Featured6').insertAfter('.NormalCardWrapper13');
		$('.Desktop .Featured7').insertAfter('.NormalCardWrapper14');
		$('.Desktop .Featured8').insertAfter('.NormalCardWrapper15');
		$('.Desktop .Featured9').insertAfter('.NormalCardWrapper18');
		$('.Desktop .Featured10').insertAfter('.NormalCardWrapper21');
		
		
		FeaturedCardDesktop();
	}
	
	// moving elements on window resize
	$(function () {
		WindowSize();  // on page load, check window size and update the css

		$(window).resize(function () { // on window resize update the css
		WindowSize();
		});
	});
	function WindowSize() {
		var w = $('#BodyWrapper').width();
		if (w <= 729) {
			FeaturedCardMobile();
		} else if (w <= 960) {
			$('.FeaturedCard').appendTo('#FeaturedCards');
			FeaturedCardDesktop();
		} else {
			$('.Desktop .Featured1').insertAfter('.NormalCardWrapper2');
			$('.Desktop .Featured2').insertAfter('.NormalCardWrapper5');
			$('.Desktop .Featured3').insertAfter('.NormalCardWrapper6');
			$('.Desktop .Featured4').insertAfter('.NormalCardWrapper7');
			$('.Desktop .Featured5').insertAfter('.NormalCardWrapper10');
			$('.Desktop .Featured6').insertAfter('.NormalCardWrapper13');
			$('.Desktop .Featured7').insertAfter('.NormalCardWrapper14');
			$('.Desktop .Featured8').insertAfter('.NormalCardWrapper15');
			$('.Desktop .Featured9').insertAfter('.NormalCardWrapper18');
			$('.Desktop .Featured10').insertAfter('.NormalCardWrapper21');
			FeaturedCardDesktop();
		}
	}
	
	// remove empty p tags
	$('p').each(function() {
	    var $this = $(this);
	    if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
		$this.remove();
	});
	
	// custom scroll bars
	$('.NormalCard .Text').customScrollbar({
		preventDefaultScroll: true,
		updateOnWindowResize: true
	});
	
}; // card template
    
    
	// Fancybox
	if ($('.FancyImage').length) {
		$(".FancyImage").fancybox({
			'padding': '10',
			'autoScale': true,
			'transitionIn': 'fade',
			'transitionOut': 'fade',
			'speedIn': 400,
			'speedOut': 100,
			'overlayColor': '#111111',
			'titleShow': true,
			'titlePosition': 'outside'
		});
	}
	if ($('.FancyYouTube').length) {
		$("a.FancyYouTube").fancybox({
			'autoScale': true,
			'transitionIn': 'fade',
			'transitionOut': 'fade',
			'speedIn': 400,
			'speedOut': 100,
			'overlayColor': '#111111',
			'titleShow': true,
			'titlePosition': 'outside',
			'type': 'iframe'
		});
	}
	if ($('.FancyiFrame').length) {
		$("a.FancyiFrame").fancybox({
			'autoScale': true,
			'transitionIn': 'fade',
			'transitionOut': 'fade',
			'width'  : 1000,           // set the width
			'height' : 700,           // set the height
			'speedIn': 400,
			'speedOut': 100,
			'overlayColor': '#111111',
			'titleShow': true,
			'titlePosition': 'outside',
			'type': 'iframe'
		});
	}


    // ----------- HACK LIST START ------------------//

	$(function ()
	{
		$.getProviderById({
			providerId: VitalSite.provider.ProviderId,
			fields    : 'all'
		}, function (data)
		{
			if(data.Value.Taxonomy[1].Terms.length > 0){
				var totalTerms = data.Value.Taxonomy[1].Terms.length;
				$('#DrDetail .Tabnav').append('<li><a href="#AcceptedInsurance">Insurance</a></li>');
				$('#DrDetail .TabContainer').append('<div class="Tab ClearFix Move" id="acceptedInsurance"><h3>Insurance</h3><ul class="insuranceList"></ul></div>');
				for(var i = 0; i < totalTerms; i++){
					var termId = data.Value.Taxonomy[1].Terms[i].TermId;
					$.ajax({
						url: '/api/taxonomy/terms/'+termId,
						success: function (contents) {
							$.each(contents.RelatedTerms, function(){
								$('#acceptedInsurance .insuranceList').append('<li>' + this.Name + '</li>');
							})
						}
					});
				}
			}
		});
	});



    // combine the unordered lists in the alphabet list for services
    if ($('.AlphabetLinks .Top ul').length > 1) {
        $('.AlphabetLinks .Top ul li').each(function () {
            $('.AlphabetLinks .Top ul:first').append($(this));
            $('.AlphabetLinks .Top ul').not(':first').remove();
        });
    }
    if ($('.AlphabetLinks .Bottom ul').length > 1) {
        $('.AlphabetLinks .Bottom ul li').each(function () {
            $('.AlphabetLinks .Bottom ul:first').append($(this));
            $('.AlphabetLinks .Bottom ul').not(':first').remove();
        });
    }

    // browers wont let you use :visted styles anymore for security
    $('#Presentation #ProximityNav li.Active').prevUntil('ul').addClass('Visited');

    //adding the <sup> tag to the registered mark in the main nav
    $('#MainNav a:contains("MyChart")').html('MyChart<sup>&reg;</sup>');

    //Doctor Results -- Make all tab display first.
    $("#ProviderBrowse .TabAll").prependTo("#ProviderBrowse .Tabnav");
    $(".taxonomyTabbedResultsFilter .TabAll").prependTo(".taxonomyTabbedResultsFilter .Tabnav");

    // Find a Doctor -- Make the Address/Phone/Map tab the default tab
    // Display google map -- otherwise map only pulls in for the locations tab
    $('#DrDetail .Tabnav li:nth-child(2) a').trigger('click');
    // Remove class Tabactive from the first tab
    $('#DrDetail .Tabnav li:first-child').removeClass('Tabactive');
    // Add class Tabactive to 2nd tab                
    $('#DrDetail .Tabnav li:nth-child(2)').addClass('Tabactive');

    //Change order of tabs
    // Move office location li above profile li
    $('#DrDetail .Tabnav li:first-child').insertAfter('#DrDetail .Tabnav li:nth-child(2)');
    // Move office location div above profile div
    $('#DrDetail .TabContainer>div').addClass('Move');
    $('#DrDetail .TabContainer>div.Tab').eq(1).insertBefore($('#DrDetail .TabContainer>div.Tab').eq(0));


    //Moving text underneath radio buttons in value and actions form
    //Adding Classes to the li's
    $('.FormContainer .IndicateValue ul li:nth-child(1)').addClass('ExcellenceRadioBtn');
    $('.FormContainer .IndicateValue ul li:nth-child(2)').addClass('RespectRadioBtn');
    $('.FormContainer .IndicateValue ul li:nth-child(3)').addClass('HonestyRadioBtn');
    $('.FormContainer .IndicateValue ul li:nth-child(4)').addClass('ServiceRadioBtn');
    $('.FormContainer .IndicateValue ul li:nth-child(5)').addClass('TeamworkRadioBtn');

    // Hack to hide the initial search results on the locations page
    if ($("#LocationsSearch").length > 0 && !$("#LocationsSearch").hasClass('ModuleSearchResults')) {
        $("#LocationsSearch table").hide();
        $(".LocationsList").hide();
    }
    // ----------- HACK LIST END ------------------//

    $('.Excellence').insertAfter('.IndicateValue ul li.ExcellenceRadioBtn span.Chkbx');
    $('.Respect').insertAfter('.IndicateValue ul li.RespectRadioBtn span.Chkbx');
    $('.Honesty').insertAfter('.IndicateValue ul li.HonestyRadioBtn span.Chkbx');
    $('.Service').insertAfter('.IndicateValue ul li.ServiceRadioBtn span.Chkbx');
    $('.Teamwork').insertAfter('.IndicateValue ul li.TeamworkRadioBtn span.Chkbx');
});

//Flexslider
$(window).load(function () { 
    
    if ($('.flexslider').length ) {
	    $('.flexslider').flexslider({
		animation: "slide",
		randomize: true
	    });
    }
    
    if ($('.carousel').length ) {
	    $('.carousel').css('display','block'); //hidden in css to avoid jumping
	    $('.carousel').flexslider({
		animation: "slide",
		animationLoop: false,
		itemWidth: 270,
		itemMargin: 0,
		minItems: 1,
		maxItems: 3
	    });
    }
	
	//adding the class of Col2 for styling since IE8 doesn't recognize CSS3 styles that were included (broken styles on homepage)
	$('#ThreeColPanels .cmspanel:nth-child(2)').addClass('Col2');
});

(function ($) {
    // load google translate if desktop view.
    if ($(window).width() > 860) {
        function googleTranslateElementInit() {
            new google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'es', layout: google.translate.TranslateElement.InlineLayout.SIMPLE, gaTrack: true, gaId: 'UA-1763661-1' }, 'google_translate_element');
        }
        $('body').append('<script src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" type="text/javascript"></script>');
    }
})(jQuery);

//responsive table in calendar
function responsiveTable(tableClass, clientTablesWrapperClass, screenWidth) {
    //store TH values in array for later extraction
    var $headers = [];
    var $row = [];

    var $tableClass = "table." + tableClass;
    var $divClass = "ResponsiveTables";
    var $newTable = "NewTable";
    var $responsiveTables = "div." + $divClass;

    var $tr = $tableClass + " tr";
    var $th = $tableClass + " tr th";
    var $td = $tableClass + " tr td";
    var $rowCount = $($tr).length;
    //console.debug($rowCount);
    if ($(window).width() < screenWidth) {

        if ($($tableClass).length === 1) {

            $($tr + ":nth-child(even)").addClass("even");

            if ($($responsiveTables).length === 0) {
                $("<div/>").addClass($divClass).insertAfter($tableClass);
            }

            //Add headers to array [headers]
            var $cellCount = -1;
            $($th).each(function () {
                $headers.push(this.innerHTML);
                $cellCount++;
            });

            var $data = "";
            $($tr).each(function (h) {
                if (h < $rowCount) {
                    $data += "<table cellpadding='0' cellspacing='0' width='100%'><tbody><tr class='ColorRow'><th class='HeaderCell' colspan='2'></th></tbody></tbody></table>";
                }
            });
            $($data).appendTo($responsiveTables)
            $($responsiveTables + " table tbody tr th").each(function (i) {
                if ($(this).contents().length === 0) {

                    $($tr + ":eq(" + i + ") td:eq(0)").contents().clone().appendTo(this);
                }
                var $hd = 0;
                var $data = "";
                for (j = 0; j < $cellCount; j++) {
                    $hd++;
                    $data += "<tr><td width='50%' class='hdr'>" + $headers[$hd] + "</td><td>" + $($tr + ":eq(" + i + ") td:eq(" + $hd + ")").html() + "</td></tr>";
                }
                $($data).appendTo($responsiveTables + " table:eq(" + i + ")");
            });
            $($responsiveTables + " tr:nth-child(even)").addClass("even");
            $($responsiveTables + " table").eq(0).remove();
            $($tableClass).attr("class", "Used").hide();
        }
    } else if ($(window).width() > screenWidth) {

        $("table.Responsive tr:nth-child(even)").addClass("even");
        $("table.Used tr:nth-child(even)").addClass("even");
        $("table.Used").show().addClass("Responsive");
        $($responsiveTables).remove();
    }
}

$(window).bind("load", function () {
        responsiveTable("Responsive", "ClientTables", "600");
        $(window).resize(function () { // on window resize update the css
            responsiveTable("Responsive", "ClientTables", "600");
        });
});